.homepage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 20vw;
}

.admin-login {
  display: flex;
  justify-content: center;
}

.admin-login .form-container {
  width: 400px;
  margin-top: 20vh;
}

.admin-panel {
  margin: 10px 20px;
}

.dash-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  /* grid-template-rows: repeat(1, 110px); */
  gap: 40px;
  justify-content: center;
}

.dash-box {
  display: grid;
  align-items: center;
  color: white;
  border-radius: 20px;
  padding: 10px;
  grid-column: span 2;
  text-align: center;
}

.dash-box>div {
  display: flex;
  justify-content: space-around;
}

.dash-box>div * {
  margin: 0 !important;
}

.form {
  --form-container-size: 90vh;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: #00000050;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  backdrop-filter: blur(3px);
  --webkit-backdrop-filter: blur(3px);
}

.form>div {
  margin: 12px;
  max-height: var(--form-container-size);
}

.form-container {
  --btn-close-color: red;
  padding: 30px;
  border-radius: 30px;
  background-color: white;
}

.mailform-container {
  width: 50vw;
  min-width: 400px;
  max-height: var(--form-container-size);
  overflow-y: scroll;
}

.form-container .heading {
  margin-bottom: 30px;
}

.form-container.import-bundles,
.form-container.generate-pdfs {
  padding-left: 18px;
  padding-right: 12px;
}

.textarea-header {
  display: flex;
  justify-content: space-between;
}
.form .btn-close{
  margin-left: auto;
  padding: 5px !important;
  background-color: orangered;
}
.notification-container {
  z-index: 99999;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  bottom: 0;
  right: 0;
  margin: 30px;
}

.notification {
  background-color: #b4ffec;
  padding: 10px;
  min-width: 340px;
  border-radius: 14px;
}

.notification.error {
  background-color: rgb(255, 150, 150) !important;
}

.notification .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

._404page {
  text-align: center;
  margin-top: calc(50vh - (2.5rem / 2));
}

.MuiTableCell-head {
  font-weight: 600 !important;
}

/* PDFs Generate Section */
.btn-download-pdfs {
  top: 40px;
  transition: all 0.4s linear;
}

.btn-generate-pdfs {
  top: 0;
  transition: all 0.4s linear;
}

.pdf-placeholder-card {
  width: max-content;
  cursor: grab;
  user-select: none;
}

.pdf-placeholder-card:active {
  cursor: grabbing;
}

@media screen and (min-width: 768px) {
  .admin-panel {
    margin: 20px 40px;
  }
}

@media screen and (min-width: 1920px) {
  .admin-panel {
    margin: auto;
    max-width: 1920px;
  }
}

/* Track */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* width */
::-webkit-scrollbar-track {
  background-color: #00000070;
  border-radius: 99px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgb(241, 241, 241);
  background-clip: content-box;
  border: 2px solid transparent;
  border-radius: 99px;
}
